import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons"

const ContactsPage = () => (
  <Layout>
    <SEO title="Contacts" />
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-4">Contact us</h1>
        </div>
      </div>
    </section>
    <div class="section" style={{ "padding-top": "1rem" }}>
      <div class="columns">
        <div class="column" />
        <div class="column is-5">
          <div class="content>">
            For reservations and other enquiries, please contact us through:
            <br />
            <br />
            <h1 class="title is-size-5">
              <FontAwesomeIcon icon={faEnvelope} />
              <a href="mailto:campingsilveira@gmail.com">
                &nbsp;campingsilveira@gmail.com
              </a>
            </h1>
            <h1 class="title is-size-5">
              <FontAwesomeIcon icon={faPhone} />
              &nbsp;+351 917 741 173
            </h1>
            <h1 class="title is-size-5">
              <FontAwesomeIcon icon={faPhone} />
              &nbsp;+351 962 387 266
            </h1>
            <p><strong>The recepetion is open between 9:00 and 20:00.</strong></p>
            <br></br>
            <p>
              In the high season it is preferable to arrive in the beginning of
              the afternoon to be sure you will find a place. We have limited
              capacity, so the camping is quickly full and we only accept
              reservations for a minimum period of one week.
            </p>
            <br></br>
            <p>
              Silveira camping is recommended by several international guides as
              the Guide du Routard, the Petit Futé, the Guide Vert Michelin,
              SVR, Rustiek Kamperen and ANWB.
            </p>
          </div>
        </div>
        <div class="column" />
      </div>
    </div>
  </Layout>
)

export default ContactsPage
